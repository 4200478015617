// Note: Requires flexslider 2 buddy.
jQuery(document).ready(function($) {

	// store the slider in a local variable
	let $window = $(window);
	let flexslider = { vars:{} };

	// tiny helper function to add breakpoints
	function getGridSize() {
		return (window.innerWidth < 768) ? 1 : 2;
	}
	
	$window.load(function() {
		if ( $('.offers .flexslider').length ) {
			// Flexslider
			let myslider =  $('.offers .flexslider').flexslider({
				animation: "slide",
				itemMargin: 31,
				itemWidth: 486,
				maxItems: getGridSize(),
				minItems: getGridSize(),
				nextText: '<i class="fa-light fa-angle-right"></i><span class="hide-everywhere">Next slide</span>',
				prevText: '<i class="fa-light fa-angle-left"></i><span class="hide-everywhere">Previous slide</span>',
				slideshow: false,
				start: function(slider){
					//set slider instance to flexslider variable
					flexslider = slider;
					var allSlides = $('.single-offer');
					allSlides.removeClass('active');
					allSlides.eq(slider.currentSlide).addClass('active');
				},
				after: function(slider){
					var allSlides = $('.single-offer');
					allSlides.removeClass('active');
					allSlides.eq(slider.currentSlide).addClass('active');
				},
			});
		}
	});

	// check grid size on resize event
	$window.resize(function() {
		var gridSize = getGridSize();
		flexslider.vars.minItems = gridSize;
		flexslider.vars.maxItems = gridSize;
	});

}); /* end of as page load scripts */